import React from 'react';
import ComponentLibraryContentsItem from './component-library-contents-item.js';
import { Heading } from "@rocketseat/gatsby-theme-docs/src/components/Sidebar/styles";
import { HeadingTOC } from "../../@rocketseat/gatsby-theme-docs/components/Docs/TOC/styles";

export default function ComponentLibraryContentsList() {
    return (
        <ul>
            <li><a href="/component-library/README">Getting Started</a></li>
            <li><a href="/component-library/Contributing">Contributing</a></li>
            <HeadingTOC>Cards</HeadingTOC>
            <ComponentLibraryContentsItem component={'cla-card'}></ComponentLibraryContentsItem>
            <ComponentLibraryContentsItem component={'cla-card-header'}></ComponentLibraryContentsItem>
            <ComponentLibraryContentsItem component={'cla-card-image'}></ComponentLibraryContentsItem>
            <ComponentLibraryContentsItem component={'cla-card-media'}></ComponentLibraryContentsItem>
            <ComponentLibraryContentsItem component={'cla-card-figure'}></ComponentLibraryContentsItem>
            <ComponentLibraryContentsItem component={'cla-card-footer'}></ComponentLibraryContentsItem>
            <HeadingTOC>Form Elements</HeadingTOC>
            <ComponentLibraryContentsItem component={'cla-checkbox'}></ComponentLibraryContentsItem>
            <ComponentLibraryContentsItem component={'cla-picker-group'}></ComponentLibraryContentsItem>
            <ComponentLibraryContentsItem component={'cla-picker'}></ComponentLibraryContentsItem>
            <ComponentLibraryContentsItem component={'cla-item'}></ComponentLibraryContentsItem>
            <ComponentLibraryContentsItem component={'cla-label'}></ComponentLibraryContentsItem>
            <ComponentLibraryContentsItem component={'cla-radio'}></ComponentLibraryContentsItem>
            <ComponentLibraryContentsItem component={'cla-toggle-switch'}></ComponentLibraryContentsItem>
            <HeadingTOC>Buttons</HeadingTOC>
            <ComponentLibraryContentsItem component={'cla-button'}></ComponentLibraryContentsItem>
            <ComponentLibraryContentsItem component={'cla-user-wrapped-buttons'}></ComponentLibraryContentsItem>
            <HeadingTOC>Badges / Icons</HeadingTOC>
            <ComponentLibraryContentsItem component={'cla-badge'}></ComponentLibraryContentsItem>
            <ComponentLibraryContentsItem component={'cla-insignia'}></ComponentLibraryContentsItem>
            <ComponentLibraryContentsItem component={'cla-insignia-image'}></ComponentLibraryContentsItem>
            <ComponentLibraryContentsItem component={'cla-icon'}></ComponentLibraryContentsItem>
            <HeadingTOC>Navigation and Menus</HeadingTOC>
            <ComponentLibraryContentsItem component={'cla-menu'}></ComponentLibraryContentsItem>
            <ComponentLibraryContentsItem component={'cla-nav'}></ComponentLibraryContentsItem>
            <ComponentLibraryContentsItem component={'cla-nav-item'}></ComponentLibraryContentsItem>
            <HeadingTOC>Info / Alert</HeadingTOC>
            <ComponentLibraryContentsItem component={'cla-banner'}></ComponentLibraryContentsItem>
            <ComponentLibraryContentsItem component={'cla-popover'}></ComponentLibraryContentsItem>
            <HeadingTOC>Status</HeadingTOC>
            <ComponentLibraryContentsItem component={'cla-progress'}></ComponentLibraryContentsItem>
            <ComponentLibraryContentsItem component={'cla-progress-circle'}></ComponentLibraryContentsItem>
            <ComponentLibraryContentsItem component={'cla-status-indicator'}></ComponentLibraryContentsItem>
            <HeadingTOC>Lists</HeadingTOC>
            <ComponentLibraryContentsItem component={'cla-structured-list'}></ComponentLibraryContentsItem>
            <ComponentLibraryContentsItem component={'cla-user-list'}></ComponentLibraryContentsItem>
            <ComponentLibraryContentsItem component={'cla-user-list-item'}></ComponentLibraryContentsItem>
        </ul>
    )
}