import React from 'react';
import { Container } from "@rocketseat/gatsby-theme-docs/src/components/Docs/TOC/styles";
import ComponentLibraryContentsList from "./component-library-contents-list";
import { ComponentLibraryWrapper } from "./styles";

export default function ComponentLibraryContents() {
    return (
        <ComponentLibraryWrapper>
            <Container>
                <h2>UI Components</h2>
                <nav>
                    <ComponentLibraryContentsList></ComponentLibraryContentsList>
                </nav>
            </Container>
        </ComponentLibraryWrapper>
    )
}