import React from 'react';
import { graphql } from 'gatsby';
import Sidebar from '@rocketseat/gatsby-theme-docs/src/components/Sidebar/index'
import { Children, Container, Main } from "@rocketseat/gatsby-theme-docs/src/components/Layout/styles";
import SEO from "@rocketseat/gatsby-theme-docs/src/components/SEO";
import ComponentLibraryContents from "../components/ComponentLibraryTOC/index";

class ComponentLibraryPage extends React.Component {
    render() {
        const docsHTML = this.props.data.markdownRemark.html;

        return (
            <>
                <SEO title={'component-library'}></SEO>
                <Container>
                    <Sidebar isMenuOpen={ true }></Sidebar>
                    <Main>
                        <Children>
                            <div dangerouslySetInnerHTML={{__html: docsHTML}}></div>
                        </Children>
                        <ComponentLibraryContents></ComponentLibraryContents>
                    </Main>
                </Container>
            </>
        )
    }
}

export default ComponentLibraryPage;


export const pageQuery = graphql`
    query ComponentLibraryComponentBySlug($slug: String!) {
        markdownRemark(fields: { slug: { eq: $slug } }) {
            html
            htmlAst
        }
    }`;