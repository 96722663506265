import styled from '@emotion/styled';
import { Wrapper } from "../../@rocketseat/gatsby-theme-docs/components/Docs/TOC/styles";
import {baseColors} from "../../@rocketseat/gatsby-theme-docs/styles/theme";

export const ComponentLibraryWrapper = styled(Wrapper)`
  position: sticky;
  top: 0;
  order: 2;
  margin-top: -40px;
  padding-top: 22px;
  padding-left: 20px;
  max-height: calc(100vh);
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  max-width: 320px;
  border-left: 1px solid ${({ theme }) => theme.colors.border};

  @media (max-width: 1200px) {
    position: relative;
    top: auto;
    order: 0;
    max-width: 100%;
    margin-left: 0;
    padding-top: 0;
  }
`;